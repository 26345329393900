import { graphql, Link } from 'gatsby';
import moment from 'moment';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import FxLayout from '../components/shared/layout/layout';
import uiService from '../components/shared/ui-service';
import { mergePreviewData } from '../services/merge-preview-data';

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from 'react-share';

export const query = graphql`
  query {
    site {
      siteMetadata {
        name
        tagline
        siteUrl
      }
    }
  }
`;

export default class IndexPage extends React.Component<any, any> {
  uiService = uiService;
  constructor(props: any) {
    super(props);

    this.state = {
      packageRange: [],
      selectedPackage: -1,
      collectionLayout: true,
      sharePanelIsVisible: false,
      previewPageContext: undefined,
    };
    this.uiService.headerInverted = false;
    this.setCollectionLayout = this.setCollectionLayout.bind(this);
    this.toggleSharePanel = this.toggleSharePanel.bind(this);
  }

  setCollectionLayout() {
    this.setState({ collectionLayout: !this.state.collectionLayout });
  }

  componentDidMount() {
    const IS_BROWSER = typeof window !== 'undefined';
    const staticData = this.props.pageContext;
    if (IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__) {
      this.setState({ previewPageContext: mergePreviewData(staticData, window.__PRISMIC_PREVIEW_DATA__) });
    }
  }

  toggleSharePanel() {
    this.setState({ sharePanelIsVisible: !this.state.sharePanelIsVisible });
  }

  render() {
    const pageContext = this.state.previewPageContext || this.props.pageContext;
    const seoMeta = pageContext.seoMeta && pageContext.seoMeta.primary;
    const contentPanels = pageContext.contentPanels || [];
    const isSDTR = process.env.TENANT === 'sdtr';
    const isVisory = process.env.TENANT === 'sigma';
    const shareUrl = process.env.SITE_URL;
    const previousPage = isSDTR ? '/tax-tips' : '/news';

    let pageBG;

    if (pageContext.page && pageContext.page.node.data.background_image) {
      pageBG = {
        backgroundImage: `url(${pageContext.page.node.data.background_image.url || ''})`,
      };
    }

    return (
      <FxLayout sitemap={pageContext.sitemap}>
        {
          <Helmet>
            <title>
              {(pageContext.page && pageContext.page.node.data.title.text) +
                ' | ' +
                this.props.data.site.siteMetadata.name}
            </title>
            <meta name="keywords" content={seoMeta && seoMeta.seo_keywords.text} />
            <meta name="description" content={seoMeta && seoMeta.seo_description.text} />
            <link
              type="canonical"
              href={`${this.props.data.site.siteMetadata.siteUrl}/${pageContext.page && pageContext.page.node.url}`}
            />
          </Helmet>
        }
        <div className="fx-l-collection-item">
          <section
            className="fx-l-collection-item-hero"
            style={{
              backgroundImage: pageBG && pageBG.backgroundImage ? pageBG.backgroundImage : '',
            }}
          />
          <section className="fx-l-section">
            <div className="fx-container">
              <div className="fx-layout">
                <div className="fx-layout-col-mobile--24 fx-layout-col-tablet--4 fx-l-collection-item-aside">
                  <div className="fx-l-collection-item-share">
                    <div className="fx-share-panel">
                      <p>Share article</p>
                      <div
                        className={`fx-share-panel-content ${
                          this.state.sharePanelIsVisible ? 'fx-share-panel-content--is-visible' : ''
                        }`}
                      >
                        {pageContext.page && (
                          <FacebookShareButton
                            url={`${shareUrl}/${pageContext.page.node.url}`}
                            title={pageContext.page.node.data.title.text}
                          >
                            <FacebookIcon size={32} round={true} />
                          </FacebookShareButton>
                        )}

                        {pageContext.page && (
                          <LinkedinShareButton
                            url={`${shareUrl}/${pageContext.page.node.url}`}
                            title={pageContext.page.node.data.title.text}
                          >
                            <LinkedinIcon size={32} round={true} />
                          </LinkedinShareButton>
                        )}
                        {pageContext.page && (
                          <EmailShareButton
                            url={`${shareUrl}/${pageContext.page.node.url}`}
                            title={pageContext.page.node.data.title.text}
                            body={`${isSDTR ? process.env.SDTR_URL : isVisory ? process.env.SIGMA_URL : 'Url'}/${
                              pageContext.page.node.url
                            }`}
                          >
                            <EmailIcon size={32} round={true} />
                          </EmailShareButton>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="fx-layout-col-mobile--24 fx-layout-col-tablet--20 fx-l-collection-item-content">
                  <div className="fx-l-collection-item-previous">
                    <Link to={`${previousPage}`}>&#8592; Back to all articles</Link>
                  </div>
                  <div className="fx-l-collection-item-categories">
                    {pageContext.page &&
                      pageContext.page.node.tags.map((tag: string) => (
                        <span className="fx-media-tile-tags-item" key={tag}>
                          {tag}
                        </span>
                      ))}
                  </div>
                  <h1 className="fx-l-section-title">{pageContext.page && pageContext.page.node.data.title.text}</h1>
                  <p className="fx-l-collection-item-date">
                    {pageContext.page && pageContext.page.node.data.date_published
                      ? moment(pageContext.page && pageContext.page.node.data.date_published).format('DD MMMM YYYY')
                      : moment(pageContext.page && pageContext.page.node.first_publication_date).format('DD MMMM YYYY')}
                    {pageContext.page && pageContext.page.node.data.authors[0].author.text && (
                      <span> | {pageContext.page.node.data.authors[0].author.text}</span>
                    )}
                  </p>
                  <div
                    className="fx-l-section-body fx-l-rich-text"
                    dangerouslySetInnerHTML={{ __html: pageContext.page && pageContext.page.node.data.body.html }}
                  />
                  <div className="fx-l-section-cta--naked">
                    {pageContext.page &&
                      pageContext.page.node.data.cta_link.text &&
                      pageContext.page.node.data.cta_title.text && (
                        <Link
                          className="fx-media-tile-cta fx-btn fx-btn--primary-outlined"
                          to={pageContext.page && pageContext.page.node.data.cta_link.text}
                        >
                          {pageContext.page && pageContext.page.node.data.cta_title.text}
                        </Link>
                      )}
                  </div>
                </div>
              </div>
            </div>

            <div className="fx-l-collection-item-related">
              <div className="fx-container">
                <div className="fx-layout-col-mobile--24 fx-layout-col-tablet--20">
                  <h1 className="fx-l-section-title">You might also like</h1>
                  <div className="fx-layout">
                    {contentPanels &&
                      contentPanels.map(
                        (panels: any) =>
                          panels &&
                          panels.items.slice(0, 3).map(
                            (item: any) =>
                              item && (
                                <div
                                  className={
                                    this.state.collectionLayout
                                      ? 'fx-layout-col-mobile--24 fx-layout-col-tablet--8'
                                      : 'fx-layout-col-mobile--24 '
                                  }
                                  key={`${new Date().getMilliseconds()}-${Math.random()}`}
                                >
                                  <section
                                    className={`fx-media-tile`}
                                    key={`${new Date().getMilliseconds()}-${Math.random()}`}
                                  >
                                    <Link
                                      to={item.url}
                                      className="fx-media-tile-thumb"
                                      style={{
                                        backgroundImage: `url(${item.data.background_image.url}`,
                                      }}
                                    />
                                    <div className="fx-media-tile-content">
                                      <div className="fx-media-tile-tags">
                                        {item.tags.slice(0, 2).map((tag: string) => (
                                          <span className="fx-media-tile-tags-item" key={tag}>
                                            {tag}
                                          </span>
                                        ))}
                                      </div>
                                      <h3 className="fx-media-tile-title">
                                        <Link to={item.url}>{item.data.title.text}</Link>
                                      </h3>
                                      <p className="fx-media-tile-date">
                                        {moment(item.first_publication_date).format('ddd M, YYYY')} |{' '}
                                        {item.data.authors[0].author.text}
                                      </p>
                                    </div>
                                  </section>
                                </div>
                              )
                          )
                      )}
                  </div>
                </div>
              </div>
            </div>

            {isVisory && (
              <section
                className="fx-l-section fx-l-section--centered fx-l-section--primary"
                key={`${new Date().getMilliseconds()}-${Math.random()}`}
              >
                <div className="fx-l-section-content">
                  <div className="fx-container">
                    <h3 className="fx-l-section-title">Simplify your business with Visory.</h3>
                    <p className="fx-l-section-body">
                      Realise the change our platform can make. With a free month trial, we’ll do your bookkeeping and
                      deliver financial statements for you to keep.
                    </p>
                    <Link
                      className="fx-l-hero-cta fx-btn fx-btn--primary-outlined"
                      to={`${process.env.APP_URL}/register/signup`}
                    >
                      Get Started
                    </Link>
                  </div>
                </div>
              </section>
            )}
          </section>
        </div>
      </FxLayout>
    );
  }
}
